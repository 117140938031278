import React from 'react';
import MainLayout from '../../layouts/main';
import { Button, Typography } from '@material-ui/core';
import styled from '../../styled';
import PageHead from '../../components/PageHead';
import { sendCtaClickEvent } from '../../tracking';
import { SEO } from '../../components/SEO';
import { Link } from 'gatsby';
import { GetStarted } from '../../components/GetStarted';
import { BrunaTestimonial } from '../../components/TestimonialBar';
import { Usps } from '../../components/Usps';
import { ComparisonTableContent } from '../../components/ComparisonTable';
import { CALENDAR_LINK } from '../../constants/demo-link';

const Label = styled('span')`
  background-color: #000;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: ${(p) => p.theme.shape.borderRadius / 2}px;
  font-size: 11px;
  padding: 4px 9px;
  display: inline-block;
  margin: 12px auto;
`;

const Hr = styled('hr')`
  border: 1px solid #eee;
  margin-bottom: 48px;
  max-width: 300px;
`;

const LogosWrapper = styled('div')`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(3)}px;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const LogoContainer = styled(Link)`
  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;
  border: 1px solid #f5f4f4;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.03);
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  padding: ${(p) => p.theme.spacing(8)}px ${(p) => p.theme.spacing(4)}px;
  transform: scale(1);
  transition: transform 0.2s linear;

  &:hover {
    transform: scale(1.05);
    transition: transform 0.2s linear;
    border: 1px solid #eee;
  }
`;

const Logo = styled('img')`
  height: 48px;
  display: block;

  @media (max-width: 1200px) {
    height: 35px;
    margin: 6px;
  }
`;

const Integrations = styled('div')`
  text-align: center;
  width: 100%;
  margin: 0 auto;
  color: #333;
  max-width: 1000px;
  width: 80%;
  padding: 24px;
`;

const Headline = styled(Typography)`
  max-width: 800px;
  margin: 0 auto ${(p) => p.theme.spacing(3)}px;
  width: 90%;

  @media (max-width: 1200px) {
    font-size: ${(p) => p.theme.typography.h5.fontSize}px;
    line-height: ${(p) => p.theme.typography.h5.lineHeight};
    margin: 0 auto ${(p) => p.theme.spacing(2)}px;
  }
`;

export default () => (
  <MainLayout>
    <div>
      <SEO
        pathname="/alternatives/"
        title="Affilimate Alternatives Overview"
        description="Looking for the perfect affiliate analytics platform? Here's how Affilimate stacks up against popular alternatives."
      />
      <PageHead style={{ marginBottom: 0 }}>
        <Headline variant="h4" component="h1">
          Compare Affilimate Alternatives
        </Headline>
        <Typography
          paragraph
          style={{ maxWidth: '600px', padding: '24px', margin: '0 auto' }}
        >
          Looking for a simple, but powerful, affiliate analytics platform as a
          media publisher? Here's how Affilimate stacks up against popular
          alternatives.
        </Typography>
      </PageHead>
      <Integrations style={{ marginBottom: '48px' }}>
        <img
          src="https://storage.googleapis.com/affilimate-assets/logos/logo-black-full.png"
          style={{ maxWidth: '200px', margin: '0 auto 48px' }}
        />
        <div style={{ marginBottom: '24px' }}>
          <LogosWrapper>
            <LogoContainer to="/alternatives/trackonomics/">
              <Label>VS</Label>
              <Logo
                src="/images/logos/trackonomics.png"
                alt="Trackonomics Alternatives"
              />
            </LogoContainer>
            <LogoContainer to="/alternatives/affluent/">
              <Label>VS</Label>
              <Logo
                src="/images/logos/affluent.jpeg"
                alt="Affluent Alternatives"
              />
            </LogoContainer>
            <LogoContainer to="/alternatives/voluum/">
              <Label>VS</Label>
              <Logo src="/images/logos/voluum.png" alt="Voluum Alternatives" />
            </LogoContainer>
          </LogosWrapper>
        </div>
      </Integrations>
      <Usps />
      <ComparisonTableContent />
      <BrunaTestimonial />
      <GetStarted />
      <Hr />
      <div style={{ margin: '24px auto 48px', textAlign: 'center' }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Not sure which is right for your site?
        </Typography>
        <Typography
          paragraph
          style={{ margin: '24px auto', maxWidth: '800px' }}
        ></Typography>
        <Button
          variant="outlined"
          color="primary"
          href={CALENDAR_LINK}
          target="_blank"
          style={{ marginRight: '8px' }}
          onClick={() => {
            sendCtaClickEvent({ label: 'content' });
          }}
        >
          Book a demo
        </Button>
        or
        <Button
          variant="outlined"
          color="primary"
          style={{ marginLeft: '8px' }}
          href="mailto:sales@affilimate.com"
        >
          Get in touch
        </Button>
      </div>
    </div>
  </MainLayout>
);
